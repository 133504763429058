.wrapper {
    background: #190533;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.image {
    height: auto;
    width: 100%;
    margin-bottom: 10px;
    border: 5px solid white;
    border-radius: 5px;
}

.progressBarBorder {
    border: 2px solid #ffffff;
    border-radius: 5px;
    margin: 10px 0;
}

.progressBar {
    height: 20px;
    background-color: #7626DD;
}

.itemName {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
}

.badge {
    border-radius: 5px;
    padding: 5px 7px;
    font-size: 14px;
    font-weight: 700;
    margin-top: auto;
}

.legendaryBadge {
    background-color: #bc2c64;
    color: #290915;
}

.rareBadge {
    background-color: #60a5fa;
    color: #1e3b8a;
}

.uncommonBadge {
    background-color: #10b981;
    color: #064e3b;
}

.commonBadge {
    background-color: white;
    color: #290915;
}

.unique {
    background: rgb(131,58,180);
    background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
    color: #290915;
}

@media screen and (max-width: 480px) {
    .itemName {
        font-size: 16px;
    }
} 