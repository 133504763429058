.wrapper {
    background-color: #2F0F58; 
    width: 100%;
}

.container {
    height: 300px;
    width: 100%;
    max-width: 1280px;
    width: 100%;
    height: auto;
    margin: auto;
    padding: 30px;
}

.headingBlock {
    width: 100%;
    text-align: center;
    margin: 80px 0 80px 0;
}

.heading {
    font-size: 48px;
    font-family: 'Bebas Neue', cursive;
    margin-top: 30px;
}

.subheading {
    color: #9B5DEA;
    font-weight: 700;
}

.headingParagraph {
    max-width: 550px;
    margin: auto;
}