.footer {
    background-color: #2F0F58; 
    width: 100%;
}

.container {
    height: 300px;
    width: 100%;
    max-width: 1280px;
    width: 100%;
    height: auto;
    margin: auto;
    padding: 30px;
    display: flex;
}

.description {
    font-size: 14px;
}

.footerIcon {
    border-radius: 100%;
    height: 48px;
}

.logo {
    height: 35px;
    margin-left: 10px;
}

.block {
    display: flex;
    flex-direction: column;
    margin-left: 160px;
}

.descriptionContainer {
    max-width: 480px;
    width: 100%;
}

.identityContainer {
    display: flex;
    align-items: center;
}

.linksContainer {
    display: flex;
}

.soonText {
    opacity: .5;
    color: gray;
}

.link {
    margin-bottom: 20px;
    text-decoration: none;
    color: #fff;
}

.link:hover {
    color: #9B5DEA;
}

@media screen and (max-width: 480px) {
    .container {
        flex-direction: column;
    }

    .block {
        margin-left: 0;
    }
/* 
    .block:not(last-child) {
        margin-right: 60px;
    } */

    .linksContainer {
        justify-content: center;
        grid-gap: 60px;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
    }
} 