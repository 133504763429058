.wrapper {
    background-color: #7626DD;
    width: 100%;
    position: relative;
}

.container {
    height: 300px;
    width: 100%;
    max-width: 1280px;
    width: 100%;
    height: auto;
    margin: auto;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headingBlock {
    width: 100%;
    text-align: center;
    margin: 80px 0;
}

.heading {
    font-size: 48px;
    font-family: 'Bebas Neue', cursive;
    margin-top: 30px;
}

.subheading {
    color: #9B5DEA;
    font-weight: 700;
}

.headingParagraph {
    max-width: 550px;
    margin: auto;
    padding: 20px 0;
}

.pattern {
    bottom: 0;
    height: 320px;
}

@media screen and (max-width: 480px) {
    .pattern {
        bottom: 0;
        height: 100px;
    }

    .headingParagraph {
        padding: 10px 0;
    }

    .headingBlock {
        margin-bottom: 50px;
    }
}