.wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.itemGrid {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 480px) {
    .itemGrid {
        grid-template-columns: 1fr;
        grid-gap: 15px;
    }
} 

