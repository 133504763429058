.header {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100px;
    z-index: 5;
    transition: all .3s ease-out;
}

.scrolled {
    background-color: #2F0F58;
    border-bottom: 1px solid #fff;
}

.container {
    max-width: 1280px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin: auto;
}

.menuItem {
    cursor: pointer;
    margin-left: 20px;
    text-decoration: none;
    color: white;
    transition: all .3s ease-in;
    font-weight: 700;
}

.menuItem:hover {
    opacity: .7;
}

.logo {
    height: 40px;
}

.linkContainer {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 480px) {
    .logo {
        height: 28px;
    }

    .linkContainer {
        font-size: 14px;
    }
}