.wrapper {
    max-height: 60px;
    background-color: #2F0F58; 
    overflow: hidden;
}

.wrapper:not(last-child) {
    margin-bottom: 10px;
}

.header {
    padding: 20px;
    cursor: pointer;
    position: relative;
    padding-right: 5px;
}

.body {
    padding: 0 20px 20px 20px;
    color: #D8C0F5;
}

.open {
    max-height: 100% !important;
}

.chevronItem {
    position: absolute;
    right: 20px;
    height: 18px;
    width: 18px;
    transform: rotate(90deg);
    transition: all .3s ease-out;
}

.chevronRotated {
    transform: rotate(-90deg);
}
/* 
@media screen and (max-width: 480px) {
    .wrapper {
        max-height: 78px;
        background-color: #2F0F58; 
        overflow: hidden;
    }
} */