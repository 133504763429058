.container {
    margin-top: 180px;
    height: 600px;
    max-width: 1280px;
    width: 100%;
    position: relative;
    padding: 0 20px;
    background-color: #2F0F58;
    position: relative;
    /* overflow: hidden; */
}

.heroImage {
    height: auto;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    z-index: 3;
}

.subHeading {
    color: #C3A0F0;
    font-weight: 700;
}

.heading {
    font-family: 'Bebas Neue', cursive;
    font-size: 72px;
    margin: 30px 0;
}

.textBlock {
    width: 50%;
    z-index: 2;
    position: relative;
}

.paragraph {
    max-width: 400px;
}

.clipart {
    position: absolute;
    bottom: 0;
    left: -80px;
    height: 645px;
    z-index: 1;
}

@media screen and (max-width: 480px) {
    .container {
        overflow: hidden;
    }

    .heading {
      font-size: 48px;
    }

    .textBlock {
        width: 100%;
        text-align: center;
    }

    .heroImage {
        height: auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: 65%;
    }

    .clipart {
        height: 230px;
    }
}