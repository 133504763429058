.button {
    background-color: #10b981;
    border: none;
    color: white;
    cursor: pointer;
    width: 100%;
    padding: 5px 7px;
    font-weight: 700;
    font-size: 16px;
    border-radius: 2px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}